import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import logo from "../asset/Logo-2.png";
import cartLogo from "../asset/shopping-cart.svg";
import profileLogo from "../asset/noun_profile_2068277.svg";
import Mask from "../asset/Mask Group 1.png";
import Menu from "./Menu";
import Cards from "./Cards";
import SearchIcon from "../asset/Group 35.png";
import Cart from "./Cart";
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const Section = ({ Sdata, cart, cost, ItemAlready, addToCart, removeFromCart ,sortCourses ,WishAlready,addToWishList , wishlist}: any) => {
    const [query, setquery] = useState("");
    const toggle = (event: any) => {
        const data = event.target.value.toUpperCase();
        console.log(data);
        setquery(data);
    }
    return (
        <>
            <section>
                <div className="Ad">
                    <div className="bottomLeft"></div>
                </div>
                <div className="main">
                    <div className="main1">
                        <div>All Courses</div>
                        <select className="main2" name="course-price"onChange={(event) => sortCourses(event.target.value)} >
                            <option selected disabled hidden> Course Price</option>
                            <option value="ascen">Low to High</option>
                            <option value="descen">High to Low</option>
                        </select>
                    </div>
                    <div className="main2">
                        {(<Cards Sdata={Sdata} addToCart={addToCart} cart={cart} addToWishList = {addToWishList}/>)}
                    </div>
                </div>
                <div className="asideDetail">
                    <div className="searchBox">
                        <input id="srchBoxText" type="text" placeholder="Search here" onChange={toggle} value={query} ></input>
                    </div>
                    <div className="CartDetails">
                        <h4>Your Cart Details</h4>
                        { cart.map(function (props: any) { return ( ( <Cart props={props} query={query} />) ) })}
                        < div className="CountPrice" >
                            <div className="Count">
                                <h6>Total Cart Value</h6>
                                <h4>Rs {cost}/-</h4>
                            </div>
                            <div className="Checkout">
                                <p>Click on Cart on Navbar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Section;