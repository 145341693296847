import React from 'react';
import ReactDOM from 'react-dom';
import arrow from '../asset/Path 20.svg';
import deleteicon from '../asset/DeleteIcon.svg';
import './Wishlist.css'
const MyWishListCard = ({ props, addToCart, removeFromWishList }: any) => {
    return (
        <>  
            <div className="WishListCardsCss">
                <div className="blank"></div>
                <div className="WishListCardsCss1">
                    <div id="course-title1">{props.name}</div>
                    <div id="tag2">{props.tag1}</div>
                </div>
                <div className="WishListCardsCss4">{props.author}</div>
                <div className="WishListCardsCss5">Rs {props.price}/-</div>
                <div className="WishListCardsCss6">{props.discount}</div>
                <button className="WishListCardsCss7" onClick={() => addToCart(props)}>Add Cart</button>
                <button className ="WishListCardsCss8" onClick = {() => removeFromWishList(props)}><img src = {deleteicon}></img></button>
                <div className="WishListCardsCss9"><img src={arrow}></img></div>
            </div>

        </>
    );
}
export default MyWishListCard;