import React from "react";
import ReactDOM from "react-dom";
import arrow from '../asset/Path 20.svg';
import star from '../asset/Path 28 (1).svg'
import './Carts.css';
const Cart = ({ props, query }: any) => {
    if (query === "" || query[0] === props.name[0])
        return (
            <>
                <div className="CartsCss">
                    <div className="blank"></div>
                    <div className="CartsCss1">
                        <div id="course-title">{props.name}</div>
                    </div>
                    <div className="CartsCss5">Rs {props.price}/-</div>
                </div>
            </>

        );
    else
            return <p></p>
}
export default Cart;