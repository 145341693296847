import React from 'react';
import ReactDOM from 'react-dom';
import './Checkout.css';
import CheckoutCards from './CheckoutCards';
import arrow from '../asset/Path 20.svg';
import star from '../asset/Path 28 (1).svg'
const Checkout = ({ Sdata, cart, cost, ItemAlready, addToCart, removeFromCart, WishAlready, addToWishList, removeFromWishList, wishlist, }: any) => {
    let popular: string = "React";
    let a = 0, b = 0, c = 0;
    for (let i = 0; i < cart.length; i++) {
        if (cart[i].tag1 === "React")
            a++;
        else if (cart[i].tag1 === "Java")
            b++;
        else if (cart[i].tag1 === "Python")
            c++;
        let m = Math.max(a, b, c);
        if (m === a)
            popular = "React";
        else if (m === b)
            popular = "Java";
        else if (m === c)
            popular = "Python";
    }
    return (
        <>
            <div className="CheckoutWrapper">
                <div className="CheckoutList">
                    <div className="CheckoutItems">
                        <h4>{cart.length} Courses in the cart</h4>
                        {(
                            <CheckoutCards cart={cart} removeFromCart={removeFromCart} WishAlready={WishAlready} addToWishList={addToWishList} wishlist={wishlist} />)
                        }
                    </div>
                    <div className="CheckoutRecommend">
                        <h4>Recommended Courses </h4>
                        {
                            Sdata.map(function (props: any) {
                                if (props.tag1 === popular)
                                    return (
                                        <div className="WCardCss">
                                            <div className="Wblank"></div>
                                            <div className="WCardsCss1">
                                                <div id="Wcourse-title">{props.name}</div>
                                                <div id="Wtag1">{props.tag1}</div>
                                            </div>
                                            <div className="WCardsCss4">{props.author}</div>
                                            <button className="WCardsCss9" onClick={() => addToWishList(props)}><img src={star} /></button>
                                            <div className="WCardsCss5">Rs {props.price}/-</div>
                                            <div className="WCardsCss6">{props.discount}</div>
                                            <button className="WCardsCss7" onClick={() => addToCart(props)}>Add Cart</button>
                                            <div className="WCardsCss8"><img src={arrow}></img></div>
                                        </div>
                                    )
                            })
                        }
                    </div>
                </div>
                <div className="CheckoutAside">
                    <h6 id="text1">Total Amount</h6>
                    <h4 id="text2">Rs {cost}/- </h4>
                    <h6 id="text3">You have saved Rs 1080/-</h6>
                    <button id="CheckoutAsideButton" onClick={() => { alert("Checkout Done") }}>Checkout</button>
                </div>
            </div>
        </>
    );
}
export default Checkout;