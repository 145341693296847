import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import dp from '../asset/User.png';
import './ProfilePage.css'

const ProfilePage = ({profiledata , updateFieldChange} : any) => {
    const checkForNumbers = (val: string) => {
        for (var i = 0; i < val.length; i++)
            if (val[i] === "0" || val[i] === "1" || val[i] === "2" || val[i] === "3" || val[i] === "4" || val[i] === "5" || val[i] === "6" || val[i] === "7" || val[i] === "8" || val[i] === "9")
                return true;
        return false;
    }
    const validation = () => {
        let username = (document.getElementById("TextField1") as HTMLInputElement).value;
        let firstname = (document.getElementById("TextField2") as HTMLInputElement).value;
        let lastname = (document.getElementById("TextField3") as HTMLInputElement).value;
        let AboutYou = (document.getElementById("TextField4") as HTMLInputElement).value;

        let radios = document.getElementsByName("sp");
        let formValid = false;
    
        let i = 0;
        while (!formValid && i < radios.length) {
            if ((radios[i] as HTMLInputElement).checked) formValid = true;
            i++;        
        }
    
        if (!formValid){
            (document.getElementById("radiobutton1") as HTMLInputElement).innerHTML = "** Must check one button";
        }else{
            (document.getElementById("radiobutton1") as HTMLInputElement).innerHTML = "";

        }

        radios = document.getElementsByName("years");
        formValid = false;
        i = 0;

        while (!formValid && i < radios.length) {
            if ((radios[i] as HTMLInputElement).checked) formValid = true;
            i++;        
        }
    
        if (!formValid){
            (document.getElementById("radiobutton2") as HTMLInputElement).innerHTML = "** Must check one button";
        }else{
            (document.getElementById("radiobutton2") as HTMLInputElement).innerHTML = "";
        }
        
        radios = document.getElementsByName("option1");
        formValid = false;
        i = 0;

        while (!formValid && i < radios.length) {
            if ((radios[i] as HTMLInputElement).checked) formValid = true;
            i++;        
        }
    
        if (!formValid){
            (document.getElementById("radiobutton3") as HTMLInputElement).innerHTML = "** Must check one button";
        }else{
            (document.getElementById("radiobutton3") as HTMLInputElement).innerHTML = "";
        }
    
        if (username === "") {
            (document.getElementById("validationOnUserName") as HTMLInputElement).innerHTML = "* Value cant be empty";
            return false;
        } else if (checkForNumbers(username) === true) {
            (document.getElementById("validationOnUserName") as HTMLInputElement).innerHTML = "* Name must include characters Only";
            return false;
        } else {
            (document.getElementById("validationOnUserName") as HTMLInputElement).innerHTML = "";
        }

        if (firstname === "") {
            (document.getElementById("validationOnFirstName") as HTMLInputElement).innerHTML = "* Value cant be empty";
            return false;
        } else if (checkForNumbers(firstname) === true) {
            (document.getElementById("validationOnFirstName") as HTMLInputElement).innerHTML = "* Name must include characters Only";
            return false;
        } else {
            (document.getElementById("validationOnFirstName") as HTMLInputElement).innerHTML = "";
        }


        if (lastname === "") {
            (document.getElementById("validationOnLastName") as HTMLInputElement).innerHTML = "* Value cant be empty";
            return false;
        } else if (checkForNumbers(lastname) === true) {
            (document.getElementById("validationOnLastName") as HTMLInputElement).innerHTML = "* Name must include characters Only";
            return false;
        } else {
            (document.getElementById("validationOnLastName") as HTMLInputElement).innerHTML = "";
        }

        if (AboutYou === "") {
            (document.getElementById("validationOnAboutYou") as HTMLInputElement).innerHTML = "* Value cant be empty";
            return false;
        } else if (AboutYou.length <= 10) {
            (document.getElementById("validationOnAboutYou") as HTMLInputElement).innerHTML = "* Min Word Limit id 10";
            return false;
        } else {
            (document.getElementById("validationOnAboutYou") as HTMLInputElement).innerHTML = "";
        }
    }
    const inputevent1 = (event : any)=>{
        updateFieldChange(event.target.value);
    }
    return (
        <>
            <form action="#" onSubmit={validation}>
                <div className="ProfileWrapper">
                    <div className="DisplayPhoto">
                        <img src={dp}></img>
                    </div>
                    <div className="mainContent">
                        <div className="row1">
                            <div className="UserName">
                                <p>Display Name</p>
                                <input id="TextField1" type="text" placeholder = "Enter username" autoComplete="off" onChange = {inputevent1} defaultValue = {profiledata[0].username}></input>
                                <span id="validationOnUserName" className="error"></span>
                            </div>
                            <div className="FirstName">
                                <p>First Name</p>
                                <input id="TextField2" type="text" placeholder="Write Your First Name" autoComplete="off"></input>
                                <span id="validationOnFirstName" className="error"></span>

                            </div>
                            <div className="LastName">
                                <p>Last Name</p>
                                <input id="TextField3" type="text" placeholder="Write Your Last Name" autoComplete="off"></input>
                                <span id="validationOnLastName" className="error"></span>
                            </div>
                        </div>
                        <div className="row2">
                            <p>About You</p>
                            <input id="TextField4" type="text" placeholder="Write About your Self" autoComplete="off"></input>
                            <span id="validationOnAboutYou" className="error"></span>
                        </div>
                        <div className="row3" id="areaOfIntrest" >Your Area of Interest
                        <div id="intrest" >
                                <input type="checkbox" id="user1" />
                                <label>&nbsp;Designer</label>
                            </div>
                            <div id="intrest" >
                                <input type="checkbox" id="user1" />
                                <label>&nbsp;Developer</label></div>
                            <div id="intrest">
                                <input type="checkbox" id="user1" />
                                <label>&nbsp;Project Manager</label></div>
                            <div id="intrest">
                                <input type="checkbox" id="user1" />
                                <label>&nbsp;Sales</label></div>
                        </div>
                        <div className="data">
                            <div id="Profession" className="row4">Are you student or Professional
                                <div id="user1_row4">
                                    <input type="radio" id="Student" name="sp" />
                                    <label>&nbsp;Student</label>
                                </div>
                                <div id="user1_row4">
                                    <input type="radio" id="Professional" name="sp" />
                                    <label>&nbsp;Professional</label>
                                </div>
                                <span id = "radiobutton1" className = "error"></span>
                            </div>
                            <div className="row5">
                                <p>How much of experience you have? </p>
                                <div id="experience">
                                    <div id="exp">
                                        <input type="radio" id="op1" name="years" />
                                        <label>&nbsp;0-5</label>
                                    </div>
                                    <div id="exp">
                                        <input type="radio" id="op2" name="years" />
                                        <label>&nbsp;5-10</label>
                                    </div>
                                    <div id="exp">
                                        <input type="radio" id="op3" name="years" />
                                        <label>&nbsp;10 n Above</label>
                                    </div>
                                </div>
                                <span id = "radiobutton2" className = "error"></span>

                            </div>
                            <div className="row6">
                                <p>What is your expertise</p>
                                <div id="expertise">
                                    <div id="expert">
                                        <input type="radio" id="Java" name="option1" />
                                        <label>&nbsp;Java</label>
                                    </div>
                                    <div id="expert">
                                        <input type="radio" id="React" name="option1" />
                                        <label>&nbsp;React</label>
                                    </div>
                                    <div id="expert">
                                        <input type="radio" id="Backend" name="option1" />
                                        <label>&nbsp;Backend</label>
                                    </div>
                                </div>
                                <span id = "radiobutton3" className = "error"></span>

                            </div>
                            <div>
                                <p>Mention your role</p>
                                <input type="TextField5" placeholder="Role" autoComplete="off"></input>
                            </div>
                        </div>
                        <button type="submit" id="btn" name="submit" value="submit">Save</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ProfilePage;