import React from 'react';
import ReactDOM from 'react-dom';
import logo from "../asset/Logo-2.png";
import cartLogo from "../asset/shopping-cart.svg";
import profileLogo from "../asset/noun_profile_2068277.svg";
import Mask from "../asset/Mask Group 1.png";
import { NavLink } from 'react-router-dom';
import Section from './Section';
import ProfilePage from './ProfilePage';
import Wishlist from './Wishlist';
const Menu = () => {
    return (
        <>
            <header>
                <div className="navFirstColumn">
                    <NavLink exact activeClassName="wishlist" to="/"><img src={logo}></img></NavLink>
                </div>
                <div className="navSecondColumn">
                    <NavLink exact activeClassName="wishlist" to="/">COURSES</NavLink>
                    <NavLink exact activeClassName="wishlist" to="/wishlist"> WISHLIST</NavLink>
                    <NavLink exact activeClassName="wishlist" to="/checkout"><img src={cartLogo}></img></NavLink>
                    <NavLink exact activeClassName="wishlist" to="/profile"><img src={profileLogo}></img></NavLink>
                </div>
            </header>
        </>
    )
}

export default Menu;