import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from "react-router-dom";
import { Link } from 'react-router-dom';
import './Wishlist.css';
import MyWishListCard from './MyWishlistCard';
import Cart from './Cart';
interface Sdata {
    name: string;
    author: string;
    price: number;
    tag1: string;
    tag2: string;
    discount: number;
}
const Wishlist = ({Sdata ,cart , cost , ItemAlready , addToCart , removeFromCart , wishAlready ,addToWishList , removeFromWishList, wishlist}:any) => {
    
    return (
        <>
                <div className = "WishListWrapper">
                    <div className = "WishList">
                        <h4>My WishList {wishlist.length}</h4>
                        {
                            wishlist.map((props:Sdata) =>{
                            return(
                                <MyWishListCard 
                                    props = {props}
                                    addToCart = {addToCart}
                                    removeFromWishList = {removeFromWishList}
                                />
                            )
                            })
                        }
                    </div>
                    <div className = "WishListCartDetails">
                        <h4>Your Cart Details</h4>
                        {
                            cart.map((props : Sdata)=>{
                              return(  
                                <Cart
                                    props = {props}
                                    query = {""}
                                /> 
                              )
                            })
                        }
                        <h5 className = "text1">Total Cart Value</h5>
                        <h3 className = "text1">Rs {cost}/-</h3>
                    </div>
                </div>
        </> 
    );
}
export default Wishlist;