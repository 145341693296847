import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProfilePage from './ProfilePage';
import Section from './Section';
import Menu from './Menu';
import Wishlist from './Wishlist';
import Checkout from './Checkout';
interface Sdata {
    name: string;
    author: string;
    price: number;
    tag1: string;
    tag2: string;
    discount: number;
}
interface Profiledata{
    username:string;
    firstname:string;
    lastname:string;
    about:string;
    interset :string;
    profession : string;
    experience:string;
    expert:string;
    role:string;
}
const Home = () => {

    // wishlist :- useState for storing the items in wishlist
    const [wishlist, setwishlist] = useState<Sdata[]>(() => { return ([]) });

    // setcost :- For calculating the final price
    const [cost, setcost] = useState(0);

    //cart :- useState for storing the items in cart
    const [cart, setcart] = useState<Sdata[]>(() => { return ([]) });

    // filtertype :- useState for Sorting the Course List
    const [filtertype, setFiltertype] = useState("course-type")

    //Sdata :- All the Courses Data are stored here.
    const [Sdata, setSdata] = useState([
        {
            name: "AResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 502,
            tag1: "React",
            tag2: "React",
            discount: 10
        },
        {
            name: "AResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 564,
            tag1: "Java",
            tag2: "React",
            discount: 10
        },
        {
            name: "AResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 565,
            tag1: "Java",
            tag2: "React",
            discount: 10
        },
        {
            name: "BResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 522,
            tag1: "React",
            tag2: "React",
            discount: 10
        },
        {
            name: "BResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 532,
            tag1: "Java",
            tag2: "React",
            discount: 10
        },
        {
            name: "BResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 542,
            tag1: "React",
            tag2: "React",
            discount: 10
        },
        {
            name: "CResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 552,
            tag1: "Java",
            tag2: "React",
            discount: 10
        },
        {
            name: "CResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 562,
            tag1: "Python",
            tag2: "React",
            discount: 10
        },
        {
            name: "DResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 572,
            tag1: "Python",
            tag2: "React",
            discount: 10
        },
        {
            name: "DResponsive Design Course XYZ How to design responsive templates",
            author: "Joseph Marie",
            price: 582,
            tag1: "Python",
            tag2: "React",
            discount: 10
        },
    ])


    // function to check if item already present in the cart list
    let ItemAlready = (product: Sdata): number => {
        for (let i = 0; i < cart.length; i++)
            if (product.price === cart[i].price) {
                alert('Item already in cart');
                return 0;
            }
        alert('Item added to cart');
        return 1;
    }
    let WishAlready = (product: Sdata): number => {
        for (let i = 0; i < wishlist.length; i++)
            if (product.price === wishlist[i].price) {
                alert('Item already in wishlist');
                return 0;
            }
        alert('Item added to wishlist');
        return 1;
    }

    // function to add item in the cart 
    const addToCart = (product: Sdata) => {
        if (ItemAlready(product) === 1) {
            setcart([...cart, { ...product }]);
            setcost(cost + product.price);
        }
    }

    // function to remove the item from the cart
    const removeFromCart = (productToRemove: Sdata) => {
        setcart(cart.filter((product) => product !== productToRemove));
        setcost(cost - productToRemove.price);
    }

    // function to add the item to wishlist
    const addToWishList = (product: Sdata) => {
        if (WishAlready(product) === 1) {
            setwishlist([...wishlist, { ...product }]);
        }
    }

    // function to remove the item from the cart
    const removeFromWishList = (productToRemove: Sdata) => {
        setwishlist(wishlist.filter((product) => product !== productToRemove));
    }

    // useEffect for sorting the courseList
    useEffect(() => {
        if (filtertype === "ascen") {
            let newCoursedata = [...Sdata];
            setSdata(newCoursedata.sort((a: Sdata, b: Sdata) => {
                let A = Math.floor(a.price * (1 - a.discount / 100));
                let B = Math.floor(b.price * (1 - b.discount / 100));
                return A > B ? 1 : -1;
            }))
        }
        else if (filtertype === "descen") {
            let newCoursedata = [...Sdata];
            setSdata(newCoursedata.sort((a: Sdata, b: Sdata) => {
                let A = Math.floor(a.price * (1 - a.discount / 100));
                let B = Math.floor(b.price * (1 - b.discount / 100));
                return B > A ? 1 : -1;
            }))
        }
    })

    // function to sort the course-list
    const sortCourses = (selectedType: string) => {
        setFiltertype(selectedType)
    }

    const[profiledata,setprofiledata] =useState(
        [
            {
                username: "Username",
                firstname: "First Name",
                lastname:"Last Name",
                about:"About Us",
                interset : "none",
                profession : "Student",
                experience: "option1",
                expert: "Java",
                role: "Role",
            },
        ]
    );

    const updateFieldChange = (value : string ) => {
        let newArr = [...profiledata];
        newArr[0].username = value;  
        setprofiledata(newArr);
    }
    
    
    return (
        <>
            <BrowserRouter>
                <Menu />
                <Switch>
                    <Route exact path="/"> <Section
                        Sdata={Sdata}
                        cart={cart}
                        cost={cost}
                        ItemAlready={ItemAlready}
                        addToCart={addToCart}
                        removeFromCart={removeFromCart}
                        sortCourses={sortCourses}
                        WishAlready={WishAlready}
                        addToWishList={addToWishList}
                        wishlist={wishlist}
                    /> </Route>
                    <Route exact path="/profile">
                        <ProfilePage 
                            profiledata = {profiledata}
                            updateFieldChange = {updateFieldChange}
                        />
                    </Route>
                    <Route exact path="/wishlist">
                        <Wishlist
                            Sdata={Sdata}
                            cart={cart}
                            cost={cost}
                            ItemAlready={ItemAlready}
                            addToCart={addToCart}
                            removeFromCart={removeFromCart}
                            WishAlready={WishAlready}
                            addToWishList={addToWishList}
                            removeFromWishList={removeFromWishList}
                            wishlist={wishlist}
                        />
                    </Route>
                    <Route exact path="/checkout">
                        <Checkout
                            Sdata={Sdata}
                            cart={cart}
                            cost={cost}
                            ItemAlready={ItemAlready}
                            addToCart={addToCart}
                            removeFromCart={removeFromCart}
                            WishAlready={WishAlready}
                            addToWishList={addToWishList}
                            removeFromWishList={removeFromWishList}
                            wishlist={wishlist}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    );
}
export default Home;