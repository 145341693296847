import React from 'react';
import ReactDOM from 'react-dom';
import Section from './components/Section';
import Home from './components/Home';
import "./index.css";
import { BrowserRouter ,Route , Switch } from "react-router-dom";
ReactDOM.render(
  <Home/>
    ,
  document.getElementById('root')
);