import React from 'react';
import ReactDOM from 'react-dom'
import deleteicon from '../asset/DeleteIcon.svg'
const CheckoutCards = ({ cart, removeFromCart ,WishAlready ,addToWishList,wishlist }: any) => {
    return (
        <>
            {                
                cart.map(function (props: any) {
                    return (
                        <div className="CheckOutCardsCss">
                            <div className="blanks"></div>
                            <div id="course-title">{props.name}</div>
                            <div id="author-name">{props.author}</div>
                            <button className="CheckoutCardsCss3" onClick = {() => addToWishList(props)}>Move to Wishlist</button>
                            <div className="CheckoutCardsCss4">Rs {props.price}/-</div>
                            <button className="CheckoutCardsCss5" onClick={() => removeFromCart(props)}><img src = {deleteicon}></img></button>
                        </div>
                    )
                }
                )}
        </>
    );
}

export default CheckoutCards;