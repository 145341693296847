import React from 'react';
import ReactDOM from 'react-dom';
import arrow from '../asset/Path 20.svg';
import star from '../asset/Path 28 (1).svg'
import './Cards.css';
const Cards = ({Sdata , addToCart ,cart ,addToWishList , query}: any) => {
    return (
        <>
            {
                Sdata.map( function (props: any) {
                return (
                    <div className="CardsCss">
                        <div className="blank"></div>
                        <div className="CardsCss1">
                            <div id="course-title">{props.name}</div>
                            <div id="tag1">{props.tag1}</div>
                        </div>
                        <div className="CardsCss4">{props.author}</div>
                        <button className="CardsCss9" onClick = {()=>addToWishList(props)}><img src={star} /></button>
                        <div className="CardsCss5">Rs {props.price}/-</div>
                        <div className="CardsCss6">{props.discount}</div>
                        <button className="CardsCss7" onClick={() => addToCart(props)}>Add Cart</button>
                        <div className="CardsCss8"><img src={arrow}></img></div>
                    </div>
                )
            }
        )}
        </>
    )
}
export default Cards;